import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";

const SpecCard = ({ document }) => {
  const { title, desc, btn } = document;
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.3, delay: 0.3 }}
      className="relative col-span-4 lg:col-span-2 w-full border-l-2  flex justify-center items-center  text-white md:p-20 p-10"
    >
      <div className="absolute -left-2  top-4 rounded-full w-4 h-4 bg-white "></div>
      <div className="text-start w-full">
        <h1 className="text-xl md:text-3xl mb-4 font-extrabold">{title}</h1>
        <h1 className="text-sm text-gray-400">{desc}</h1>
        <Link to={"/connect"}>
          <button className="btn mt-4 btn-sm rounded-lg rounded-bl-none">
            Click here{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </Link>
      </div>
    </motion.div>
  );
};

export default SpecCard;
