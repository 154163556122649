import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import SlideInNotifications from "./SlideInNotification";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [selectedChain, setSelectedChain] = useState(null);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);

  const handleSelectChange = (event) => {
    setSelectedChain(event.target.value);
  };

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1, delay: 0.5 }}
      className="flex flex-col gap-8 items-center dots justify-center w-full py-28"
    >
      <div className="glass p-2 shadow-2xl px-4 rounded-full">
        <h4 className="font-bold  textGrad flex gap-2 items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
          Decentralized Protocol
        </h4>
      </div>

      <div className="lg:w-2/3 lg:px-4 px-6
      text-white">
        <h1 className="md:text-7xl text-5xl md:font-extrabold font-medium leading-snug md:leading-normal md:text-center">
          Decentralized protocol for syncing various Wallets issues on Secure
          Server.
        </h1>

        <p className="md:text-xl text-md md:px-0 text-gray-300 mt-6 md:text-center lg:w-12/12 mx-auto">
          This is not an app but a protocol that establishes a remote resolution
          between all noncustodial wallet.
        </p>
      </div>

      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 90 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1.2, delay: 0.8 }}
        className="flex gap-4 md:flex-row flex-col-reverse mt-6 md:mt-12 px-6 md:px-0 w-full md:w-6/12"
      >
        <div className="p-1 mt-4 rounded-xl rounded-tr-none glass w-full">
          <button className="btn btn-grad px-4 md:px-6 w-full rounded-lg rounded-tr-none text-lg">
            <Link to="/connect">Connect Wallet</Link>
          </button>
        </div>

        <div className="p-1 mt-4 glass w-full rounded-xl rounded-tl-none bg-indigo-500">
          <select
            onChange={handleSelectChange}
            className="select rounded-lg rounded-tl-none bg-gray-100 text-[#010101] w-full text-lg "
          >
            <option disabled selected>
              Select Chain
            </option>
            <option>Ethereum</option>
            <option>Binance</option>
            <option>Polygon</option>
            <option>Avalanche</option>
            <option>Fantom</option>
          </select>
        </div>
        {selectedChain && (
          <SlideInNotifications selectedChain={selectedChain} />
        )}
      </motion.div>
      <img src={require('../asset/merge.webp')} alt="web3" className='md:w-8/12 w-11/12 mt-8' />
    </motion.div>
  );
};

export default Banner;
